export function fmtAddress(
  property: Partial<{
    address?: string
    address2?: string
    city?: string
    stateAbbr: string
    postalCode: string
  }>,
  oneLine: boolean = false
) {
  const lineOne = [property.address, property.address2]
    .filter((v) => v)
    .join(', ')
  const lineTwo = [
    property.city,
    property.postalCode
      ? `${property.stateAbbr} ${property.postalCode}`
      : property.stateAbbr,
  ]
    .filter((v) => v)
    .join(', ')
  if (oneLine) {
    return [lineOne, lineTwo].filter((v) => v).join(', ')
  }
  return [lineOne, lineTwo].filter((v) => v).join('\n')
}

export function fmtDate(date: Date) {
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${date.getFullYear()}/${month}/${day}`
}

export function fmtPhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

export function fmtNumberAbbr(num?: number): string {
  if (!num) {
    return ''
  }
  const formatted = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(num)
  if (formatted === '0') {
    return num.toString()
  }
  return formatted
}

export function fmtName(
  firstName?: string | null,
  lastName?: string | null
): string {
  const first = firstName?.trim()
  const last = lastName?.trim()

  if (first && last) return `${first} ${last}`

  return first || last || ''
}

export function fmtPlumbingCapacity(diameter?: number, capacity?: number) {
  let output = ''

  if (diameter) {
    output += `${fmtNumberAbbr(diameter)}"`
  }

  if (capacity) {
    if (output.length > 0) {
      output += ` / `
    }

    output += `${fmtNumberAbbr(capacity)} gal`
  }

  return output
}

export function fmtPower(volts?: number, amps?: number, phase?: string) {
  let power = ''

  if (volts) {
    power += `${volts} V`
  }

  if (amps) {
    if (power.length > 0) {
      power += ' @ '
    }

    power += `${amps} A`
  }

  if (phase) {
    if (power.length > 0) {
      power += ', '
    }

    power += phase
  }

  return power
}
